<template>
  <div>
    <section id="page-title" class="page-title-parallax page-title-center page-title-dark include-header" style="background-image: linear-gradient(to top, rgba(254,150,3,0.5), #39384D), url('/assets/images/services/banner/customerexperience.jpg'); background-size: cover; padding: 120px 0;" data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">
        <div id="particles-line"></div>

        <div class="container clearfix mt-4">
            <div class="badge badge-pill border border-light text-light">Customer Experience</div>
            <h1>Customer Experience</h1>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Customer Experience</li>
            </ol>
        </div>

    </section>
    <section id="content">

			<div class="content-wrap pb-0">
                <!-- Main Content
				============================================= -->
				<div class="container">
					<div class="row align-items-end">
						<div class="col-lg-6">
							<div class="badge badge-pill badge-default">Customer Experience</div>
							<h2 class="display-4 font-weight-bold">Empowering Customer Service Professionals.</h2>
							<p>
                                We build world-class teams to strengthen your core business processes. Our team of specialists are reliable, accountable, and dedicated to alleviate your pain points. Learn more about empowering your customer support team below:
                            </p>
							<router-link to="/contact"><a class="button button-rounded button-large nott ls0">Get Started</a></router-link>
						</div>
						<div class="col-lg-6 mt-4 mt-lg-0">
							<img src="/assets/images/services/header/customerexperience.jpg" alt="Image">
						</div>
					</div>
				</div>
				<!-- Features
				============================================= -->
				<div class="section mb-0">
					<div class="container">
						<div class="heading-block border-bottom-0 center">
							<div class="badge badge-pill badge-default">Services</div>
							<h3 class="nott ls0">What We Can Do</h3>
							<p>We build world-class teams to strengthen your core business processes. Our team of specialists are reliable, accountable, and dedicated to alleviate your pain points. Learn more about empowering your customer support team below:</p>
						</div>
						<div class="row col-mb-30 align-content-stretch">

							<div class="col-lg-4 col-md-6 d-flex">
								<div class="card">
									<div class="card-body p-5">
										<div class="feature-box flex-column">
											<div class="mb-3">
												<img src="/assets/images/services/pagecards/customerexp1.jpg" alt="Feature Icon" class="bg-transparent rounded-0" style="height:90%;">
											</div>
											<div class="fbox-content">
												<h3 class="nott ls0 text-larger">CHAT/ IN-APP MESSAGING SUPPORT</h3>
												<p>
                                                    Our agents are highly proficient in managing and optimizing multiple and concurrent conversations through personalized and predetermined responses. We operate efficiently in identifying pain points for customers and providing immediate feedback and assistance.
                                                </p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-lg-4 col-md-6 d-flex">
								<div class="card">
									<div class="card-body p-5">
										<div class="feature-box flex-column">
											<div class="mb-3">
												<img src="/assets/images/services/pagecards/customerexp2.jpg" alt="Feature Icon" class="bg-transparent rounded-0">
											</div>
											<div class="fbox-content">
												<h3 class="nott ls0 text-larger">EMAIL SUPPORT</h3>
												<p>
                                                    We understand that support emails have a profound impact on your brand. Our skilled customer support team responds promptly to your customers using your preferred voice and tone.
                                                </p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-lg-4 col-md-6 d-flex">
								<div class="card">
									<div class="card-body p-5">
										<div class="feature-box flex-column">
											<div class="mb-3">
												<img src="/assets/images/services/pagecards/customerexp3.jpg" alt="Feature Icon" class="bg-transparent rounded-0">
											</div>
											<div class="fbox-content">
												<h3 class="nott ls0 text-larger">PHONE SUPPORT</h3>
												<p>
                                                    Our customer support agents speak excellent English and have extensive experience with both inbound and outbound support for various partners. With the team’s stellar communication skills, we guarantee to meet your customers’ needs in a way that reflects positively on your business.
                                                </p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Contact/>
			</div>
	</section>
  </div>
</template>

<script>
// @ is an alias to /src
import Contact from '@/components/ContactUs.vue'

export default {
	name: 'CustomerEXP',
	data() {
		return {

		}
	},
	components: {
		Contact
	},
	computed: {
		
	},
	methods: {
		
	},
	mounted() {
		window.scrollTo(0, 0)
	}
}
</script>
